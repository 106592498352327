const DOMINIO = window.location.protocol + '//' + window.location.host;

$('.pane-cursos-reslizados a[data-toggle="collapse"]').click(function(event) {
	event.preventDefault();
	var a = $(this);

	a.removeClass('fadeIn animated');

	$($(this).attr('href')).on('shown.bs.collapse', function(){
		a.text('Fechar').addClass('fadeIn animated');
	});

	$($(this).attr('href')).on('hidden.bs.collapse', function(){
		a.text('Abrir').addClass('fadeIn animated');
	});
});