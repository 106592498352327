function startOwlCarousel(alvo, options){
	if($(alvo).children().length > 1){
		$(alvo).owlCarousel(options);
	}else{
		$(alvo).addClass('owl-loaded');
	}
}

var inscricoes = startOwlCarousel('#inscricoes',{
	responsive: {
		992: {
			items: 4
		},
		600: {
			items: 2
		},
		0: {
			items: 1
		}
	},
	loop: true,
	autoplay: true
});

$('[data-owl="prev"]').click(function(event) {
	event.preventDefault();

	var alvo = $(this).attr('href');
	
	$(alvo).trigger('prev.owl.carousel');
});

$('[data-owl="next"]').click(function(event) {
	event.preventDefault();

	var alvo = $(this).attr('href');

	$(alvo).trigger('next.owl.carousel');
});