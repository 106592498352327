$('.nav-bar').affix({
	offset: {
		top: function(){
			return (this.top = $('.topo').outerHeight(true));
		}
	}
});

$('.nav-bar').on('affix.bs-affix', function(){
	if ($(window).width() < 992){
		$('.topo').css('padding-bottom', $('.nav-bar').outerHeight());
	}
});